import accesexport from "@content/tutorial/images/acces-export.png";
import nouvelexport from "@content/tutorial/images/nouvel-export.png";

import { PageLayout, Section, Note } from "@components";

import "@styles/Page.scss";

export default function FeatExport() {
  return (
    <PageLayout title="Exporter l'étude" roles={["admin"]}>
      <Section id="accéder-à-l-export" title="Accéder à l'export">
        <p>
          Une fois toutes les données de vos patients réunies, vous pouvez
          exporter les données afin de les visualiser, les traiter, les
          analyser. Pour se faire on se rend dans l'onglet <b>Analyse</b> puis{" "}
          <b>Exports</b>.
        </p>
        <img src={accesexport} alt="Onglet exports" className="centered" />
        <h2 id="exporter-des-données">Exporter des données</h2>
        <p>
          Cliquez sur <b>Nouvel export</b> afin de faire une demande d'export,
          celle-ci sera résolu dans les 5 minutes qui suivront votre demande.
        </p>
        <img src={nouvelexport} alt="Nouvel export" className="centered" />
        <Note type="note">
          <p>
            Si la demande d'export n'apparaît pas immédiatement, cliquez sur le
            bouton "<b>Rechargement</b>" en bas de la liste.
          </p>
        </Note>
      </Section>
    </PageLayout>
  );
}
